import { useEffect, useState } from "react";
const Modal = ({ x, y, onClose, ModalData }) => {
    const [IsIos, setIsIos] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            setIsIos(true);
        } else if (/Android/i.test(userAgent)) {
            setIsIos(false);
        }
    }, []);
    const ColorCodes = (status) => {
        switch (status) {
            case 1:
                return {
                    Dark: "rgba(24, 139, 24, 1)",
                    Light: "rgba(24, 139, 24, 0.5)",
                    Status: "Available",
                };
            case 2:
                return {
                    Dark: "rgba(25, 153, 161, 1)",
                    Light: "rgba(25, 153, 161, 0.5)",
                    Status: "Registered",
                };
            case 3:
                return {
                    Dark: "rgba(239, 72, 54, 1)",
                    Light: "rgba(239, 72, 54, 0.5)",
                    Status: "Allotted",
                };
            case 4:
                /*  return {
                    Dark: "rgba(255, 140, 0, 0.6)",
                    Light: "rgba(255, 215, 0, 0.6)",
                    Status: "Reserved",
                }; */
                return {
                    Dark: "rgba(239, 72, 54, 1)",
                    Light: "rgba(239, 72, 54, 0.5)",
                    Status: "Allotted",
                };
            case 5:
                /* return {
                    Dark: "rgba(108, 71, 71, 0.6)",
                    Light: "rgba(217, 175, 175, 0.6)",
                    Status: "Blocked",
                }; */
                return {
                    Dark: "rgba(239, 72, 54, 1)",
                    Light: "rgba(239, 72, 54, 0.5)",
                    Status: "Allotted",
                };
            case 7:
                return {
                    Dark: "rgba(24, 139, 24, 1)",
                    Light: "rgba(24, 139, 24, 0.5)",
                    Status: "Available",
                };
            default:
                return {
                    Dark: "rgba(0, 0, 0, 0.6)",
                    Light: "rgba(255, 255, 255, 0.6)",
                    Status: "Unknown",
                };
        }
    };
    return (
        <div
            className="modal"
            style={{ top: y, left: x }}
            onMouseEnter={() => {}}
            onMouseLeave={onClose}>
            <table className="data-wrapper">
                <tbody>
                    <tr>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            <b>Plot No </b>
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            {ModalData?.plot_number}
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            <b>Sector</b>
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            {ModalData.sector_type}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            <b>Area </b>
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            {ModalData.plot_area_sqy}{" "}
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            <b>Facing </b>
                        </td>
                        <td style={{ fontSize: IsIos ? "8px" : "15px" }}>
                            {ModalData.facing_type}
                        </td>
                    </tr>{" "}
                    {ModalData?.dimensions &&
                        ModalData?.dimensions?.length > 0 && (
                            <tr>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    <b
                                        style={{
                                            fontSize: IsIos ? "8px" : "15px",
                                        }}>
                                        {ModalData.dimensions[0].name} :
                                    </b>
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    {ModalData.dimensions[0].LengthSide}
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    <b>{ModalData.dimensions[1].name} </b>
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    {ModalData.dimensions[1].LengthSide}
                                </td>
                            </tr>
                        )}
                    {ModalData?.dimensions &&
                        ModalData?.dimensions?.length > 0 && (
                            <tr>
                                {" "}
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    <b>{ModalData.dimensions[2].name} :</b>
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    {ModalData.dimensions[2].LengthSide}
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    <b>{ModalData.dimensions[3].name}</b>
                                </td>
                                <td
                                    style={{
                                        fontSize: IsIos ? "8px" : "15px",
                                    }}>
                                    {ModalData.dimensions[3].LengthSide}
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            <div
                colSpan={2}
                className={"status"}
                style={{
                    color: "white",
                    backgroundColor: ColorCodes(ModalData?.plot_status).Dark,
                    margin: IsIos ? "5px" : "1rem",
                    fontSize: IsIos ? "8px" : "15px",
                }}>
                <span
                    style={{
                        fontSize: IsIos ? "8px" : "15px",
                    }}>
                    {ModalData?.isMortgage == 1 && "MD-"}
                    {ColorCodes(ModalData?.plot_status).Status}
                </span>
            </div>
            {/* <button onClick={onClose}>Close</button> */}
        </div>
    );
};

export default Modal;
